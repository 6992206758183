import React, { useState, useEffect, useMemo } from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { AddShoppingCart } from "@mui/icons-material"
import ProductsToReceive from "./ProductsToReceive"
import ProductsReceived from "./ProductsReceived"
import { useSelector, useDispatch, batch } from "react-redux"
import {
    showOrderList,
    addSupplierItemToOrderReception,
    showOrderSupplierItemQuantities,
    showOrderDeliveryNote,
    completeOrderDeliveryNoteUnitPrices, showOrderDeliveryNoteControl, initializeOrderReception, clearSelectedOrderReception, clearOrderCreditNotes, startReceptionTreament,
} from "../../../actions/OrderReception/OrderReception"
import { useCallback } from "react"
import SupplierItemModal from "../../../components/Order/Reception/SupplierItemModal"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import OrderReceptionCreditNote from "./OrderReceptionCreditNote"
import { duplicateOrderAction, showOrdersList } from "../../../actions/Orders/Orders"
import {STORAGE_ORDER_RECEPTION_KEY} from "../../../utils/orderReception"
import { showBillingAndCreditNotesList } from "../../../actions/BillingCreditNotes/BillingCreditNotes"
import {Stack, Typography} from "@mui/material"
import RefuseOrderReception from "./RefuseOrderReception"
import MoreMenu from "../../../components/MoreMenu"
import OrderReceptionLots from "./OrderReceptionLots"
import OrderReceptionHeaderTitle from "./OrderReceptionHeaderTitle"
import OrderReceptionHeaderInfo from "./OrderReceptionHeaderInfo"
import ProductionStepExecutionStatus from "../../ProductionStepExecutions/ProductionStepExecutionsStatus"
import {getPSEStatusLabel} from "../../../utils/productionStepExecution"
import {StyledItemLabel, StyledItemValue, StyledMainContainer} from "../../ProductionStepExecutions/styledComponents"
import {PEPSEResumeContainer} from "../../ProductionStepExecutions/preview/StyledExecutionPreviewComponents"
import {CollapsibleButton} from "./CollapsibleButton"
import ActionFooter from "../../../components/common/ActionFooter"

const useStyles = makeStyles(() => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    tab: {
        paddingLeft: 45,
        paddingRight: 45,
    },
    root: {
        zIndex: 1000,
        maxHeight: "fit-content",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw"
    },
    title: {
        fontSize: 20,
        lineHeight: 1,
        color: "#000",
        fontWeight: 500,
    },
    appBar: {
        backgroundColor: COLORS.WHITE,
        borderBottom: `2px solid ${COLORS.LIGHT_GREY_3}`,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
    },
    headerBloc: {
        flex: "0 0 72px"
    },
    tabsContainer: {
        display: "flex",
        flexDirection: "row",
        borderBottom: `1px solid ${COLORS.LIGHT_GREY_3}`
    },
    lotsTab: {
        marginLeft: "auto"
    },
    btnContainer: {
        display: "flex",
        alignItems: "center"
    },
    lastTab: {
        marginLeft: "auto"
    },
    floatingButton: {
        position: "absolute",
        bottom: 20,
        left: 20
    },
    firstBtn: {
        marginRight: 20
    },
    creditNotesTab: {
        marginRight: 0
    }
}))

export const SingleOrderReception = () => {
    const localStorage = window.localStorage
    const navigationHistory = JSON.parse(localStorage.getItem("singleOrderReceptionNavigationHistory")) || {}
    const lastPath = navigationHistory.lastPath || ""

    const classes = useStyles()
    const dispatch = useDispatch()
    const orderReception = useSelector(state => state.orderReception.selectedOrderReception)
    const parseSupplier = useSelector(state => state.orderReception.parseSupplier)
    const date = useSelector(state => state.orderReception.date)
    const site = useSelector(state => state.orderReception.site)
    const creditNotes = useSelector(state => state.orderReception.creditNotes)
    const lots = useSelector(state => state.orderReception.lots)
    const orderType = useSelector(state => state.billingCreditNotes.orderType)
    const ordersBySites = useSelector(state => state.billingCreditNotes.sites)
    const [showSupplierItemModal, setShowSupplierItemModal] = useState(false)
    const [tabValue, setTabValue] = useState(0)
    const [articlesTodo, setArticlesTodo] = useState([])
    const [articlesDone, setArticlesDone] = useState([])
    const [canEdit, setCanEdit] = useState(false)

    useEffect(() => {
        if (!orderReception) return
        if (orderReception.supplierItems) {
            setArticlesTodo(orderReception.supplierItems.filter(order => order.receptionStatus === "TODO"))
            setArticlesDone(orderReception.supplierItems.filter(order => order.receptionStatus === "DONE"))
        }

        const selectedOrderReceptionIdStorage = JSON.parse(localStorage.getItem(STORAGE_ORDER_RECEPTION_KEY))

        if (selectedOrderReceptionIdStorage && orderReception.objectId === selectedOrderReceptionIdStorage) {
            setCanEdit(true)
        } else {
            localStorage.removeItem(STORAGE_ORDER_RECEPTION_KEY)
        }

    }, [orderReception])

    useEffect(() => {
        if (orderType && orderType === "CREDIT_NOTE") {
            setTabValue(3)
        }
    }, [orderType])

    const onTabChange = (e, tabValue) => {
        setTabValue(tabValue)
    }

    const onEditItem = useCallback((orderSupplierItemId) => {
        if (canEdit || orderReception.receptionStatus === "TODO") return
        dispatch(showOrderSupplierItemQuantities(site.objectId, date, orderReception.objectId, orderSupplierItemId))
    }, [orderReception, canEdit])

    const onClickReturn = useCallback(() => {
        if (orderType && orderType === "CREDIT_NOTE") {
            const sitesIds = ordersBySites ? ordersBySites.map((site) => site.objectId) : []
            batch(() => {
                dispatch(clearOrderCreditNotes())
                dispatch(clearSelectedOrderReception())
                dispatch(showBillingAndCreditNotesList(sitesIds))
            })
            return
        }

        localStorage.setItem("useStoredDataGridFilters", "1")
        if (!canEdit) {
            dispatch(showOrderList(site.objectId, date))
            return
        }

        dispatch(showOrdersList(site.objectId))
        localStorage.removeItem(STORAGE_ORDER_RECEPTION_KEY)
    }, [site, date, orderReception, canEdit])

    const addSupplierItem = useCallback((supplierItemId) => {
        dispatch(addSupplierItemToOrderReception(orderReception.objectId, supplierItemId))
    }, [orderReception])

    const handleDeliveryNote = useCallback(async () => {
        dispatch(showOrderDeliveryNote(site.objectId, date, orderReception.objectId))
    }, [site, date, orderReception])

    const handleDeliveryNoteControl = useCallback(async () => {
        await dispatch(completeOrderDeliveryNoteUnitPrices(orderReception))
        dispatch(showOrderDeliveryNoteControl(site.objectId, date, orderReception.objectId))
    }, [site, date, orderReception])

    const duplicateOrder = useCallback(() => {
        dispatch(duplicateOrderAction(orderReception.objectId, site.objectId))
    }, [orderReception, site])

    const moreMenus = useMemo(() => {
        if (lastPath.split("/").at(0) === "orders") {
                return [
                    {
                        label: "Dupliquer la commande",
                        onClick: duplicateOrder,
                        isVisible: !!orderReception
                    }
                ].filter(({ isVisible }) => isVisible)
        }
        return []
    }, [lastPath, orderReception])

    const onInitializeOrderReception = useCallback((orderSupplierItemId) => {
        if (canEdit) return
        dispatch(initializeOrderReception(orderReception.objectId, orderSupplierItemId))
    }, [orderReception, canEdit])


    const startTreatReception = () => {
        dispatch(startReceptionTreament(orderReception.objectId))
    }

    const renderFooterAction = () => {
        if (orderReception?.receptionStatus === "TODO") {
            return (
                <Stack direction="row" spacing={2}>
                    <RefuseOrderReception orderReception={orderReception} />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={startTreatReception}
                    >
                        Commencer la réception
                    </Button>
                    <MoreMenu menus={moreMenus} />
                </Stack>
            )
        }
        return (
            <div className={classes.btnContainer}>
                <div>
                    {
                        (!canEdit && orderReception) &&
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.firstBtn}
                            onClick={handleDeliveryNote}
                        >
                            {orderReception.deliveryNote ?
                                "Modifier le numéro de BL + Photos" :
                                "Ajouter le numéro de BL + Photos"
                            }
                        </Button>
                    }
                </div>
                <div>
                    {
                        (!canEdit && (orderReception && orderReception.receptionStatus === "IN_PROGRESS")) &&
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={articlesTodo.length !== 0 || !orderReception.deliveryNote}
                            onClick={handleDeliveryNoteControl}
                        >
                            Vérifier le bon de Livraison
                        </Button>
                    }
                </div>
                <MoreMenu menus={moreMenus} />
            </div>
        )
    }

    const weightOrder = orderReception?.totalWeight
    return (
      <div className={classes.wrapper}>
          <div className={classes.root}>
              <GenericTopBarHeader
                leftAction={<GenericHeaderReturnButton handleClick={onClickReturn} />}
                title={<OrderReceptionHeaderTitle sx={{ fontSize: 16 }}/>}
                info={orderReception && <OrderReceptionHeaderInfo info={`/ ... / Commande n°${orderReception.orderNumber}`}/>}
              />
          </div>
          <StyledMainContainer> {/** for the fixed header */}
              <Stack>
                  <Stack sx={{ p: 3 }} gap={3}>
                      <PEPSEResumeContainer sx={{ position: "relative" }}>
                          <Stack spacing={4}>
                              {/* head */}
                              <Stack spacing={2.2} direction="row" alignItems="center">
                                  <Typography variant="h1" className={classes.title}>
                                      Commande n°{orderReception && orderReception.orderNumber}
                                  </Typography>
                                  <ProductionStepExecutionStatus
                                    statusValue={orderReception && orderReception.receptionStatus}
                                    statusName={getPSEStatusLabel(orderReception && orderReception.receptionStatus)}
                                  />
                              </Stack>
                              {/* resume */}
                              <Stack spacing={4} direction="row">
                                  {/* transformation */}
                                  <Stack spacing={1}>
                                      <StyledItemLabel>
                                          Fournisseur
                                      </StyledItemLabel>
                                      <StyledItemValue>
                                          {orderReception && orderReception.supplier && orderReception.supplier.name}
                                      </StyledItemValue>
                                  </Stack>
                                  {/* recipe & section */}
                                  <Stack spacing={1}>
                                      <StyledItemLabel>
                                          Montant total
                                      </StyledItemLabel>
                                      <StyledItemValue>
                                          {orderReception && orderReception.totalAmount} €
                                      </StyledItemValue>
                                  </Stack>
                                  <Stack spacing={1}>
                                      <StyledItemLabel>
                                          Poids total
                                      </StyledItemLabel>
                                      <StyledItemValue>
                                          {weightOrder} kg
                                      </StyledItemValue>
                                  </Stack>
                              </Stack>
                          </Stack>
                      </PEPSEResumeContainer>
                      <Tabs
                        value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                        onChange={onTabChange}
                        textColor="primary"
                        indicatorColor="primary"
                        sx={{
                            borderBottom: "1px solid #E6E6E6",
                            "& .MuiTab-root": {
                                padding: "16px 24px"
                            },
                            marginTop: "8px"
                        }}
                      >
                          <Tab label={`produits à réceptionner (${articlesTodo.length})`}  classes={{ root: classes.tab }} />
                          <Tab label={`produits réceptionnés (${articlesDone.length})`} classes={{ root: classes.tab }} />
                          <Tab classes={{ root: classes.tab }} label={`Lots créés (${lots && lots.length})`} />
                          <Tab classes={{ root: classes.tab }} label={`avoirs et réclamations (${creditNotes.length})`} />
                      </Tabs>
                      <Stack spacing={4} mb={4}>
                          {
                            tabValue === 0 &&
                            <ProductsToReceive
                              orderSupplierItems={articlesTodo}
                              onEditItem={onEditItem}
                            />
                          }

                          {
                            tabValue === 1 &&
                            <ProductsReceived
                              articles={articlesDone}
                              onInitializeOrderReception={onInitializeOrderReception}
                              canEdit={canEdit}
                              canInitialize={orderReception && orderReception.receptionStatus === "IN_PROGRESS"}
                            />
                          }
                          {
                            tabValue === 2 &&
                            <OrderReceptionLots lots={lots} />
                          }
                          {
                            tabValue === 3 &&
                            <OrderReceptionCreditNote
                              creditNotes={creditNotes}
                              supplierItems={orderReception?.supplierItems}
                            />
                          }
                      </Stack>
                      <Stack spacing={4}>
                          {
                            !canEdit && orderReception && orderReception.receptionStatus === "IN_PROGRESS" &&
                            <CollapsibleButton>
                                <IconButton
                                  color="primary"
                                  onClick={() => setShowSupplierItemModal(true)}
                                  size="large">
                                    <AddShoppingCart />
                                </IconButton>
                            </CollapsibleButton>
                          }
                      </Stack>
                      <ActionFooter
                        rightAction={renderFooterAction()}
                      />
                      {
                        showSupplierItemModal &&
                        <SupplierItemModal
                          addSupplierItem={addSupplierItem}
                          supplier={parseSupplier}
                          onClose={() => setShowSupplierItemModal(false)}
                        />
                      }
                  </Stack>
              </Stack>
          </StyledMainContainer>
      </div>
    )
}
