import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Box, styled } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { getIsMenuOpen } from "../../reducers/Utils/app"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import PSESubstitutionDialog from "./PSESubstitutionDialog"
import ProductionSubstitutionSnackbar from "./ProductionSubstitutionSnackbar"
import AutorenewIcon from "@mui/icons-material/Autorenew"
import DataGrid from "../../components/DataGrid/DataGrid"
import Chip from "@mui/material/Chip"
import dayjs from "dayjs"
import { loadSupplierItemsToReplace } from "../../actions/Supplier/supplierItems"
import { getSupplierItemsSelector } from "../../reducers/Site/sites"
import ScheduledSubstitutionDialog from "./ScheduledSubstitutionDialog"
import { getProductionSubstitutionSnackbarSelector, getProductionSubstitutionsSelector, getProductionSubstitutionSupplierItemsOptionsSelector } from "../../reducers/ProductionSubstitution/ProductionSubstitutions"
import { getTimezoneDate } from "../../utils"


const useStyles = makeStyles({
	header: {
		color: "#2196F3",
		fontSize: "16px",
		fontWeight: 500,
		lineHeight: "16px"
	}
})

const HeaderContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "menuOpen"
})(({ menuOpen }) => ({
	zIndex: 1000,
	maxHeight: "fit-content",
	position: "fixed",
	top: 0,
	left: menuOpen ? 260 : 64,
	width: "100vw"
}))

const getSubstitutionStatus = (substitution) => {
	const now = dayjs(getTimezoneDate(new Date(), "moment"))
	const startDate = dayjs(substitution.startDate)
	const endDate = dayjs(substitution.endDate)

	if (now.isBetween(startDate, endDate, null, "[]")) {
		return "En cours"
	}
	if (now.isBefore(startDate)) {
		return "À venir"
	}
	if (now.isAfter(endDate)) {
		return "Terminé"
	}
}

const getRecipesLabel = (recipes) => {
	if (!recipes || !recipes.length) {
		return "Toutes"
	}
	return recipes.map(recipe => recipe.uniqueCode + " - " + recipe.name).join(", ")
}

const createData = (substitution) => {
	return ({
		id: substitution.objectId,
		originalSupplierItem: substitution.originalSupplierItem?.name,
		replacementSupplierItem: substitution.replacementSupplierItem?.name,
		period: dayjs.utc(substitution.startDate).valueOf(), // only the startDate to allow sorting, filter is done is customFilterFunction
		recipes: getRecipesLabel(substitution.recipes),
		status: getSubstitutionStatus(substitution)
	})
}

const ProductionSubstitutions = () => {

	const dispatch = useDispatch()

	const classes = useStyles()

	const [openSubstitutionDialog, setOpenSubstitutionDialog] = useState(false)
	const [openScheduledSubstitutionDialog, setOpenScheduledSubstitutionDialog] = useState(false)
	const [data, setData] = useState([])

	const menuOpen = useSelector(getIsMenuOpen)
	const snackbar = useSelector(getProductionSubstitutionSnackbarSelector)
	const substitutions = useSelector(getProductionSubstitutionsSelector)
	const supplierItems = useSelector(getSupplierItemsSelector)
	const supplierItemsOptions = useSelector(getProductionSubstitutionSupplierItemsOptionsSelector)

	useEffect(() => {
		if (substitutions && substitutions.length) {
			setData(substitutions.map(createData))
		}
	}, [substitutions])

	const columns = [
		{
			key: "originalSupplierItem",
			label: "Article remplacé",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: true,
				type: "select",
				options: supplierItemsOptions,
				withSearch: true
			},
		},
		{
			key: "replacementSupplierItem",
			label: "Article de remplacement",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: true,
				type: "select",
				options: supplierItemsOptions,
				withSearch: true
			},
		},
		{
			key: "period",
			label: "Période de remplacement",
			dataType: "date",
			sortable: true,
			filter: {
				filterable: true,
				type: "date",
				customFilterFunction: (productionSubstitution, filterValue) => {
					const selectedDate = dayjs(getTimezoneDate(filterValue, "moment"))
					const foundProductionSubstitution = substitutions.find(substitution => substitution.objectId === productionSubstitution.id)
					const startDate = dayjs(foundProductionSubstitution.startDate)
					const endDate = dayjs(foundProductionSubstitution.endDate)
					if (selectedDate.isBetween(startDate, endDate, null, "[]")) {
						return true
					}
					if (selectedDate.isSame(startDate, "day") || selectedDate.isSame(endDate, "day")) {
						return true
					}
					return false
				}
			},
			render: (_, substitutionId) => {
				const substitution = substitutions.find(substitution => substitution.objectId === substitutionId)
				return substitution.type === "oneOff" ? dayjs.utc(substitution.startDate).format("DD/MM/YYYY") : dayjs.utc(substitution.startDate).format("DD/MM/YYYY") + " - " + dayjs.utc(substitution.endDate).format("DD/MM/YYYY")
			}
		},
		{
			key: "recipes",
			label: "Recettes concernées",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: false
			},
		},
		{
			key: "status",
			label: "Statut",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: true,
				type: "select",
				options: ["À venir", "En cours", "Terminé"].map((status) => ({
					key: status,
					value: status,
					label: status
				}))
			},
			render: (status) => {
				return (
					<Chip sx={{
						color: "#FFF",
						fontWeight: 500,
						backgroundColor: status === "En cours" ? "#F2C046" : status === "À venir" ? "#2196F3" : "#48B468"
					}}
						label={status}
					/>
				)
			}
		}
	]

	const handleOpenScheduledSubstitutionDialog = async () => {
		if (!supplierItems.length) {
			await dispatch(loadSupplierItemsToReplace(null, true))
		}
		setOpenScheduledSubstitutionDialog(true)
	}


	const handleOpenSubstitutionDialog = async () => {
		if (!supplierItems.length) {
			await dispatch(loadSupplierItemsToReplace(null, true))
		}
		setOpenSubstitutionDialog(true)
	}

	const moreMenus = [
		{
			label: "Remplacer un article pour des productions futures",
			onClick: handleOpenScheduledSubstitutionDialog
		},
		{
			label: "Remplacer un article pour une production déjà générée",
			onClick: handleOpenSubstitutionDialog
		}
	]

	return (
		<>
			<HeaderContainer
				menuOpen={menuOpen}>
				<GenericTopBarHeader
					leftAction={<AutorenewIcon />}
					title="Remplacement d'articles"
					className={classes.header}
				/>
			</HeaderContainer>
			<Box sx={{
				height: "calc(100vh - 64px)",
				overflow: "auto",
				marginTop: "64px"
			}}>
				<DataGrid
					menus={moreMenus}
					title="Suivi des remplacements d'articles"
					data={data}
					columns={columns}
					withFilters={true}
					rowLabel="remplacements"
				/>
			</Box>
			<PSESubstitutionDialog
				open={openSubstitutionDialog}
				onClose={() => setOpenSubstitutionDialog(false)}
				supplierItems={supplierItems}
			/>
			<ScheduledSubstitutionDialog
				open={openScheduledSubstitutionDialog}
				onClose={() => setOpenScheduledSubstitutionDialog(false)}
				supplierItems={supplierItems}
			/>
			<ProductionSubstitutionSnackbar
				snackbar={snackbar} />
		</>
	)
}

export default ProductionSubstitutions