import Parse from "parse"
import { batch } from "react-redux"
import dayjs from "dayjs"
import { isBoolean } from "lodash"

import {
    actionWithLoader,
    downloadFile,
    getReplacingPath,
    onEnter,
    push
} from "../Utils/utils"
import { setValues } from "../../parseUtils"
import {
    recipeFields,
    parseLimitRequest,
    getServerUrl,
    delay
} from "../../utils"
import {
    getSelectedRecipe
} from "../../reducers/Recipe/recipes"
import { axiosCall } from "../../utils/axiosUtils"
import {
    emptyHeatingInstruction,
    parseSectionToParse,
    parseHeatingInstructions,
    recipePreparations,
    getSupplierItemsFromSections,
    createSection,
    computeIngredientsPercentage, updateRecipeFoodcost
} from "../../utils/recipes"
import {
    saveImage,
    deleteImage
} from "../../utils/images"
import { showError } from "../Utils/app"
import {
    getChefs,
    getSingleChef
} from "../../parseManager/products/resources/chef/parseChefsManager"
import {
    updateParseRecipeDetails,
    updateAllRecipesFoodcost, getRecipes, searchRecipes
} from "../../parseManager/recipe/parseRecipeManager"
import {
    getSupplierItemsWithIds
} from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"
import { getProductInCard } from "../../parseManager/recipe/parseProductionItemManager"
import { downloadSupplierItemsByReportCsv, loadSupplierItemsOptions } from "../Supplier/supplierItems"
import { getProductTypeOptions, loadProductTypeOptions } from "../ProductTypes/productTypes"
import { WITH_MANAGEMENT_MODE_FILTER } from "../../utils/productTypesUtils"

import { computeDisplayData } from "../Utils/utils"
import { loadCommercialNamesOptions } from "../Ingredients/Internal/CommercialsNames"
import { loadProductTagsOptions } from "../ProductsTags/ProductsTags"

import { getRecipes as getRecipesSelector } from "../../reducers/Recipe/recipes"
import { formatOldComment } from "../../utils/comments"

const Recipe = Parse.Object.extend("Recipe")
const Packaging = Parse.Object.extend("Packaging")
const Section = Parse.Object.extend("Section")
const ProductsTags = Parse.Object.extend("ProductsTags")
const Ingredients = Parse.Object.extend("Ingredients")

export const loadSingleRecipe = (recipeId) => {
    return actionWithLoader(async (dispatch) => {
        const recipe = await new Parse.Query(Recipe)
            .equalTo("objectId", recipeId)
            .include(["chef.image"])
            .include(["sections.steps.ingredients.cookingMode"])
            .include(["sections.steps.ingredients.supplierItem.cookingModes.cookingMode"])
            // production steps
            // TODO: remove this block when the migration of the new section production steps structure is finished
            .include(["sections.productionSteps"])
            .include(["sections.productionSteps.stepComponents.cookingMode"])
            .include(["sections.productionSteps.stepComponents.supplierItem.cookingModes.cookingMode"])
            .include(["sections.productionSteps.kitchenArea"])
            .include(["sections.productionSteps.machineType"])
            // production steps
            .include(["sections.productionSteps.step"])
            .include(["sections.productionSteps.step.stepComponents.cookingMode"])
            .include(["sections.productionSteps.step.stepComponents.supplierItem.cookingModes.cookingMode"])

            .include(["sections.productionSteps.step.kitchenArea"])
            .include(["sections.productionSteps.step.machineType"])
            // reusable production steps
            .include(["sections.productionSteps.productionSteps"])
            .include(["sections.productionSteps.productionSteps.stepComponents.cookingMode"])
            .include(["sections.productionSteps.productionSteps.stepComponents.supplierItem.cookingModes.cookingMode"])
            .include(["sections.productionSteps.productionSteps.kitchenArea"])
            .include(["sections.productionSteps.productionSteps.machineType"])

            // reusable production steps
            .include(["sections.productionSteps.step.productionSteps"])
            .include(["sections.productionSteps.step.productionSteps.stepComponents.cookingMode"])
            .include(["sections.productionSteps.step.productionSteps.stepComponents.supplierItem.cookingModes.cookingMode"])
            .include(["sections.productionSteps.step.productionSteps.kitchenArea"])
            .include(["sections.productionSteps.step.productionSteps.machineType"])

            .include(["images"])
            .include(["sections.steps.ingredients.supplierItem.commercialName"])
            .include(["sections.steps.ingredients.supplierItem.commercialName.allergens"])
            .include(["sections.productionSteps.stepComponents.supplierItem.commercialName"])
            .include(["sections.productionSteps.stepComponents.supplierItem.commercialName.allergens"])
            .include(["internalTag"])
            .include(["ingredients.supplierItem.commercialName.allergens"])
            .first()

        dispatch({
            type: "SELECTED_RECIPE_UPDATED",
            selectedRecipe: recipe
        })
    })
}

export const loadRecipes = () => {
    return actionWithLoader(async (dispatch) => {
        const productTypeOptions = await getProductTypeOptions(WITH_MANAGEMENT_MODE_FILTER)
        dispatch({
            type: "PRODUCT_TYPE_OPTIONS_LOADED",
            productTypeOptions: [...productTypeOptions]
        })

        const recipes = await getRecipes({
            selects: ["uniqueCode", "name", "commercialName", "brands", "type", "status", "isActive", "commercialNames"],
            sortBy: "updatedAt",
            sortDirection: "desc",
            toJSON: true
        })

        dispatch({
            type: "RECIPES_LOADED",
            recipes
        })
    })
}

export const refreshRecipesForAdditionalFilters = () => {
    return actionWithLoader(async (dispatch, getState) => {
        const currentRecipes = getRecipesSelector(getState())
        const recipesWithAdditionalFiltersFields = await getRecipes({
            ids: currentRecipes.map(recipe => recipe.objectId),
            selects: ["ingredients.supplierItem.name", "ingredients.supplierItem.commercialName.name", "internalTag"],
            includes: ["ingredients.supplierItem"],
            sortBy: "updatedAt",
            sortDirection: "desc",
            toJSON: true
        })
        const fieldsFromRefreshed = ["ingredients", "internalTag"]
        const refreshedRecipes = currentRecipes.map(recipe => {
            const recipeWithAdditionalFields = recipesWithAdditionalFiltersFields.find(recipeWithAdditionalFields => recipeWithAdditionalFields.objectId === recipe.objectId)
            if (!recipeWithAdditionalFields) {
                return recipe
            }
            for (const newField of fieldsFromRefreshed) {
                recipe[newField] = recipeWithAdditionalFields[newField]
            }
            return recipe
        })
        dispatch({
            type: "RECIPES_LOADED",
            recipes: refreshedRecipes
        })
    })
}

export const loadAdditionalFiltersForRecipes = () => {
    return actionWithLoader(async (dispatch) => {
        await Promise.all([
            loadCommercialNamesOptions()(dispatch),
            loadSupplierItemsOptions()(dispatch),
            loadProductTagsOptions()(dispatch)
        ])
    })
}

export function loadProductsTags() {
    return actionWithLoader(async (dispatch) => {
        const productsTags = await new Parse.Query(ProductsTags)
            .limit(parseLimitRequest)
            .ascending("name")
            .find()

        dispatch({
            type: "RECIPE_PRODUCTS_TAGS_LOADED",
            productsTags: productsTags
        })
    })
}

export function loadPackaging() {
    return actionWithLoader(async (dispatch) => {
        //PACKAGING
        const packagings = await new Parse.Query(Packaging)
            .notEqualTo("deleted", true)
            .ascending("name")
            .limit(parseLimitRequest)
            .find()

        dispatch({
            type: "RECIPE_PACKAGING_LOADED",
            packagings
        })
    })
}

export function loadChefs() {
    return actionWithLoader(async (dispatch) => {
        const chefs = await getChefs()

        dispatch({
            type: "RECIPE_CHEFS_LOADED",
            chefs
        })
    })
}

export function loadGenericSections() {
    return actionWithLoader(async (dispatch) => {
        const sections = await new Parse.Query(Section)
            .include("steps.ingredients.supplierItem")
            .include("steps.ingredients.cookingMode")
            .include("productionSteps")
            .include("productionSteps.stepComponents.supplierItem")
            .include("productionSteps.stepComponents.transformationMode")
            .include("productionSteps.stepComponents.transformRate")
            .equalTo("reusable", true)
            .limit(parseLimitRequest)
            .find()

        dispatch({
            type: "SECTIONS_LOADED",
            sections
        })
    })
}

export function clearSelectedRecipe() {
    return dispatch => {
        dispatch({
            type: "RECIPE_SELECTED",
            selectedRecipe: null
        })
    }
}

// old comment version (from tab)

export function commentRecipe(values, recipe) {
    return actionWithLoader(async (dispatch) => {
        try {
            if (!recipe || !values.hasOwnProperty("comments")) {
                return dispatch({
                    type: "RECIPE_COMMENT_SAVED_ERROR",
                    recipeSnackBar: { open: true, type: "error", message: "Les commentaires n'ont pas pu être sauvegardés", duration: 5000 }
                })
            } else {
                // reformat on new comment version
                const formattedComment = formatOldComment(values.comments, new Date(), "carte@foodcheri.com")
                if (recipe.has("comments") && typeof recipe.get("comments") === "string") {
                    recipe.unset("comments")
                    await recipe.save()
                }
                const comments = recipe.get("comments") || []
                comments.push(formattedComment)
                recipe.set("comments", comments)
                await recipe.save()

                dispatch({
                    type: "RECIPE_SAVED",
                    selectedRecipe: recipe
                })
            }
        } catch (err) {
            return dispatch({
                type: "RECIPE_COMMENT_SAVED_ERROR",
                recipeSnackBar: { open: true, type: "error", message: "Les commentaires n'ont pas pu être sauvegardés", duration: 5000 }
            })
        }
    })
}

// new comment version (from right drawer)

export const addRecipeComment = (newComment, recipe) => {
    return actionWithLoader(async (dispatch) => {
        try {
            if (!recipe || !newComment) {
                return dispatch({
                    type: "RECIPE_COMMENT_SAVED_ERROR",
                    recipeSnackBar: { open: true, type: "error", message: "Le commentaire n'a pas pu être sauvegardé", duration: 5000 }
                })
            } else {
                const currentComments = recipe.get("comments") || []
                const isOldCommentsVersion = typeof currentComments === "string"

                if (isOldCommentsVersion) {
                    const oldComment = currentComments
                    const reformattedOldComment = oldComment !== "" ? formatOldComment(oldComment, recipe.createdAt, "carte@foodcheri.com") : null
                    recipe.unset("comments")
                    // we have to do a first save to avoid parse server error when converting field from string to array
                    await recipe.save()
                    const comments = []
                    comments.push(newComment)
                    if (reformattedOldComment) {
                        comments.push(reformattedOldComment)
                    }
                    recipe.set("comments", comments)
                }
                else {
                    currentComments.push(newComment)
                    recipe.set("comments", currentComments)
                }

                await recipe.save()

                dispatch({
                    type: "RECIPE_SAVED",
                    selectedRecipe: recipe
                })
            }
        } catch (err) {
            return dispatch({
                type: "RECIPE_COMMENT_SAVED_ERROR",
                recipeSnackBar: { open: true, type: "error", message: "Le commentaire n'a pas pu être sauvegardé", duration: 5000 }
            })
        }
    })

}

/**
 * Used from recipe table, in recipe component
 * @param {*} values New values to save
 * @param {Recipe} recipe Recipe to set values in
 */
export const updateRecipeSections = (values, recipe, isProductionSteps = false) => {
    return actionWithLoader(async (dispatch) => {
        if (!recipe || !values.sections) {
            return
        }

        // not saved if undefined (from old recipe tab)
        if (isBoolean(values.switchProductionSteps)) {
            recipe.set("switchProductionSteps", values.switchProductionSteps)
        }

        // retrieve either the new value from values.switchProductionSteps set above (if from tab "productionSteps"), either the value from recipe (if from tab "recette")
        const switchProductionStepsEnabled = !!recipe.get("switchProductionSteps")

        const invalidValuesComputation = switchProductionStepsEnabled !== isProductionSteps

        if (invalidValuesComputation) {  // ensure ingredients, weight indicators, etc, are dependent on whether we use new or old recipe form
            const accurateStepsField = switchProductionStepsEnabled === true ? "productionSteps" : "steps"
            const accurateIngredientField = switchProductionStepsEnabled === true ? "stepComponents" : "ingredients"
            values = computeDisplayData({
                recipe: values,
                stepsField: accurateStepsField,
                stepIngredientField: accurateIngredientField,
                isProductionSteps: switchProductionStepsEnabled
            })
        }

        /** Update foodcost according to switchProductionSteps trigger **/
        updateRecipeFoodcost(recipe, values, switchProductionStepsEnabled)

        const sections = await parseSectionToParse(values.sections)
        recipe.set("sections", sections)

        recipe.set("netWeight", values.netWeight)

        // nb: other changes on recipe global values (grossWeight, complexity, season, tags, etc ... are done on server (in recipe afterSave))

        const supplierItemsFromSections = switchProductionStepsEnabled
            ? getSupplierItemsFromSections(values.sections, "productionSteps", "stepComponents")
            : getSupplierItemsFromSections(values.sections)

        const supplierItems = await getSupplierItemsWithIds(supplierItemsFromSections, false)

        const ingredients = switchProductionStepsEnabled
            ? computeIngredientsPercentage(values.netWeight, values.sections, supplierItems, "productionSteps", "stepComponents")
            : computeIngredientsPercentage(values.netWeight, values.sections, supplierItems)

        recipe.set("ingredients", ingredients)

        await recipe.save() // => vn sent to Etiquettable in afterSave

        await delay(4000) // wait for Etiquettable to update before retrieving vn 

        await dispatch(synchronizeARecipeWithEtiquettable(recipe.id, true))

        dispatch({
            type: "RECIPE_SAVED",
            selectedRecipe: recipe
        })
    })
}

export function createReusableSection(section) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()

        if (state.recipes.sections.find(section => section.get("name") === section.name)) {
            return dispatch({
                type: "DUPLICATE_GENERIC_SECTION_ERROR",
                recipeSnackBar: {
                    open: true,
                    type: "error",
                    message: "Une section générique avec ce nom existe déjà"
                }
            })
        }

        section.reusable = true
        section.parentId = null
        section.parentPercent = 0

        await createSection(section)
        const sections = await new Parse.Query(Section)
            .include("steps.ingredients.supplierItem")
            .include("steps.ingredients.cookingMode")
            .equalTo("reusable", true)
            .limit(parseLimitRequest)
            .find()

        dispatch({
            type: "REUSABLE_SECTION_SAVED",
            sections: sections
        })
    })
}

export function updateRecipeDetails(values, recipe) {
    return actionWithLoader(async (dispatch) => {
        await updateParseRecipeDetails(values, recipe)

        dispatch({
            type: "RECIPE_SAVED",
            selectedRecipe: recipe
        })
    })
}

export function createRecipe(values, recipe) {
    return actionWithLoader(async (dispatch) => {
        const keyToIgnore = [
            "gesters", "portionPerPlate", "packaging", "subPackaging", "price",
            "heatingInstructions", "sections", "internalTag", "ingredients", "dlc",
            "reusablePackaging", "reusableSubPackaging"
        ]

        if (!recipe) {
            recipe = new Recipe()
            recipe.set("exportedTo", [])
        }

        if (values.sections) {
            const sections = await parseSectionToParse(values.sections)
            recipe.set("sections", sections)
        }

        if (Array.isArray(values.ingredients) && values.ingredients.length > 0) {
            const ingredients = await new Parse.Query(Ingredients)
                .containedIn("objectId", values.ingredients)
                .limit(parseLimitRequest)
                .find()

            recipe.set("ingredients", ingredients)
        }
        else {
            recipe.set("ingredients", [])
        }

        if (Array.isArray(values.packaging) && values.packaging.length) {
            const packaging = await Promise.all(values.packaging.map(async (p) => {
                const packaging = await new Parse.Query(Packaging)
                    .equalTo("objectId", p.value)
                    .first()

                return { brand: p.brand, value: packaging }
            }))

            recipe.set("packaging", packaging || [])
        }

        if (Array.isArray(values.reusablePackaging) && values.reusablePackaging.length) {
            const reusablePackaging = await Promise.all(values.reusablePackaging.map(async (p) => {
                const reusablePackaging = await new Parse.Query(Packaging)
                    .equalTo("objectId", p.value)
                    .first()

                return { brand: p.brand, value: reusablePackaging }
            }))

            recipe.set("reusablePackaging", reusablePackaging || [])
        }

        if (Array.isArray(values.subPackaging) && values.subPackaging.length) {
            const subPackaging = await Promise.all(values.subPackaging.map(async (sp) => {
                const subPackagings = (await new Parse.Query(Packaging)
                    .containedIn("objectId", sp.value.map(obj => obj.value))
                    .limit(parseLimitRequest)
                    .find()) || []

                return { brand: sp.brand, value: subPackagings }
            }))

            recipe.set("subPackaging", subPackaging)
        }

        if (Array.isArray(values.reusableSubPackaging) && values.reusableSubPackaging.length) {
            const reusableSubPackaging = await Promise.all(values.reusableSubPackaging.map(async (sp) => {
                const reusableSubPackaging = (await new Parse.Query(Packaging)
                    .containedIn("objectId", sp.value.map(obj => obj.value))
                    .limit(parseLimitRequest)
                    .find()) || []

                return { brand: sp.brand, value: reusableSubPackaging }
            }))

            recipe.set("reusableSubPackaging", reusableSubPackaging)
        }

        if (values.preparation === "1") {
            recipe.set("heatingInstructions", values.heatingInstructions.map(hi => {
                return { brand: hi.brand, value: emptyHeatingInstruction }
            }))
        } else if (values.heatingInstructions && values.heatingInstructions.length) {
            recipe.set("heatingInstructions", values.heatingInstructions.map(hi => {
                return { brand: hi.brand, value: parseHeatingInstructions(hi.value) }
            }))
        }

        if (values.price && values.price.length) {
            recipe.set("price", values.price.map(p => {
                return { brand: p.brand, value: parseFloat(p.value) }
            }))
        }

        if (values.dlc && values.dlc.length) {
            recipe.set("dlc", values.dlc.map(dlc => {
                return { brand: dlc.brand, value: parseFloat(dlc.value) }
            }))
        }

        const internalTag = values.internalTag
            ? (await new Parse.Query(ProductsTags)
                .containedIn("objectId", values.internalTag.map(obj => obj.value))
                .limit(parseLimitRequest)
                .find()) || []
            : []

        recipe.set("news", values.news)
        recipe.set("internalTag", internalTag)
        recipe.set("isActive", values.isActive)
        recipe.set("sameInstructions", values.sameInstructions)
        recipe.set("sameDescriptions", values.sameDescriptions)
        recipe.set("portionPerPlate", values.portionPerPlate ? parseFloat(values.portionPerPlate) : null)
        recipe.set("gesters", values.gesters ? parseFloat(values.gesters) : null)

        Object.keys(values).forEach(function (key) {
            const val = values[key]
            if (!keyToIgnore.includes(key) && val) {
                recipe.set(key, val)
            }
        })

        await recipe.save()
  
            await dispatch(loadRecipes())
            await dispatch(showRecipeList())
        dispatch({
            type: "RECIPE_SAVED"
        })
    })
}

export function deleteRecipe(recipe, shouldRedirect = false) {
    return actionWithLoader(async (dispatch) => {
        recipe.set("deleted", true)
        await recipe.save(null, { context: { isDeletion: true } })

        dispatch({
            type: "RECIPE_REMOVED"
        })
        dispatch(loadRecipes())

        if (shouldRedirect) {
            dispatch(showRecipeList())
        }
    })
}

export function updateSelectedRecipe(values) {
    return async (dispatch, getState) => {
        const state = getState()
        const selectedRecipe = getSelectedRecipe(state) || new Recipe()
        setValues(selectedRecipe, values, recipeFields)
        dispatch({
            type: "SELECTED_RECIPE_UPDATED",
            selectedRecipe
        })
    }
}

export function closeRecipeSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        return dispatch({
            type: "CLOSE_RECIPE_SNACKBAR",
            recipeSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
        })
    })
}

export function closeEtiquettableSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        return dispatch({
            type: "CLOSE_ETIQUETTABLE_SNACKBAR",
            etiquettableSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
        })
    })
}

export function flushExportRecipesErrors() {
    return dispatch =>
        dispatch({
            type: "EXPORT_RECIPES_ERRORS",
            exportRecipesErrors: [],
            exportRecipesMessage: ""
        })
}

export function exportRecipes() {
    return actionWithLoader(async (dispatch) => {
        const url = getServerUrl() + "/recipes/exports"
        const result = await axiosCall("post", url, {}, { "Content-Type": "application/json" })
        if (result.status === 200 && result.data.success === "OK") {
            return dispatch(flushExportRecipesErrors())
        }
        else {
            return dispatch({
                type: "EXPORT_RECIPES_ERRORS",
                exportRecipesErrors: result.data.errors,
                exportRecipesMessage: result.data.message
            })
        }
    })
}

export function synchronizeEtiquettable() {
    return actionWithLoader(async (dispatch) => {
        const url = `${getServerUrl()}/recipes/_synchronizeEtiquettable`
        const result = await axiosCall("post", url, {}, { "Content-Type": "application/json" })

        if (result && result.status === 200 && result.data.success === true) {
            dispatch({
                type: "SYNCHRONIZE_RECIPES_WITH_ETIQUETTABLE",
                recipeSnackBar: {
                    open: true,
                    type: "success",
                    message: "La synchronisation avec etiquettable est un succès."
                }
            })
            dispatch(loadRecipes())
        } else {
            dispatch({
                type: "SYNCHRONIZE_RECIPES_WITH_ETIQUETTABLE_ERROR",
                recipeSnackBar: {
                    open: true,
                    type: "error",
                    message: result && result.data && result.data.message ? result.data.message : "La synchronisation avec etiquettable a échoué"
                }
            })
        }
    })
}

/**
 * send recipe data (ingredients) to etiquettable, then retrieve recipe data (nutrional values) from Etiquettable
 * @param {String} id 
 */
export const synchronizeRecipeToAndFromEtiquettable = (id, fromNewRecipeView = false) => {
    return actionWithLoader(async (dispatch) => {
        const url = `${getServerUrl()}/recipes/${id}/_synchronizeRecipeToAndFromEtiquettable`
        const result = await axiosCall("post", url, {}, { "Content-Type": "application/json" })
        if (result && result.status === 200 && result.data.success === true) {
            dispatch({
                type: "UPDATE_ETIQUETTABLE_SNACKBAR",
                etiquettableSnackBar: { open: true, type: "success", message: "Le produit interne a été synchronisé avec Etiquettable", duration: 5000, triggerDate: dayjs() }
            })
            if (fromNewRecipeView) {
                return dispatch(loadSingleRecipe(id))
            }
            dispatch(loadRecipes())
        } else {
            dispatch({
                type: "UPDATE_ETIQUETTABLE_SNACKBAR",
                etiquettableSnackBar: { open: true, type: "error", message: result && result.data && result.data.message ? result.data.message : "Le produit interne n'a pas été synchronisé avec Etiquettable", duration: 5000, triggerDate: dayjs() }
            })
        }
    })
}
/**
 * we retrieve nutrional values from Etiquettable and update our db with the informations acquired
 * @param {String} id 
 * @param {Boolean} fromNewRecipeView 
 * @param {Boolean} mustExport 
 * @returns 
 */
export function synchronizeARecipeWithEtiquettable(id, fromNewRecipeView = false) {
    return actionWithLoader(async (dispatch) => {
        const url = `${getServerUrl()}/recipes/${id}/_synchronizeEtiquettable`
        const result = await axiosCall("post", url, {}, { "Content-Type": "application/json" })

        if (result && result.status === 200 && result.data.success === true) {
            dispatch({
                type: "UPDATE_ETIQUETTABLE_SNACKBAR",
                etiquettableSnackBar: { open: true, type: "success", message: "Le produit interne a été synchronisé avec Etiquettable", duration: 5000, triggerDate: dayjs() }
            })
            if (fromNewRecipeView) {
                return dispatch(loadSingleRecipe(id))
            }
            dispatch(loadRecipes())
        } else {
            dispatch({
                type: "UPDATE_ETIQUETTABLE_SNACKBAR",
                etiquettableSnackBar: { open: true, type: "error", message: result && result.data && result.data.message ? result.data.message : "Le produit interne n'a pas été synchronisé avec Etiquettable", duration: 5000, triggerDate: dayjs() }
            })
        }
    })
}

/**
 * replace one supplier item to another one
 * @returns 
 */
export function updateAllRecipes() {
    return actionWithLoader(async () => {
        await Parse.Cloud.run("updateAllRecipes")
    })
}

export function downloadRecipeProduction(id, name, values) {
    const productionDate = values.productionDate.valueOf()
    const packagingDate = values.packagingDate.valueOf()
    const brand = values.brand

    const url = `${getServerUrl()}/recipes/${id}/production?volume=${values.volume}&productionDate=${productionDate}&packagingDate=${packagingDate}&brand=${brand}`
    const fileName = `${name}-${values.productionDate.format("DD-MM-YYYY")}.pdf`
    downloadFile(url, fileName)
}

export function createProductResume(values) {
    return actionWithLoader(async (dispatch) => {
        const recipe = new Recipe()
        const keysToArray = [
            "exportedTo", "packaging", "subPackaging", "price", "instructions",
            "heatingInstructions", "season", "sections", "internalTag", "ingredients", "dlc",
            "reusablePackaging", "reusableSubPackaging", "description", "foodCostPCT", "appImage",
            "kitchenImage", "internalTags", "tva", "commercialNames"
        ]
        const keys = ["name", "type", "brands"]
        const defaultValues = {
            appImage: null,
            price: 0,
            dlc: 0,
            description: "",
            instructions: "",
            heatingInstructions: null,
            commercialName: "",
            tva: 5.5
        }

        keysToArray.forEach(key => {
            recipe.set(key, [])
        })

        keys.forEach(key => {
            recipe.set(key, values[key])
        })

        recipe.set("isActive", false)
        recipe.set("status", "1")
        recipe.set("difficulty", 1)
        recipe.set("commercialName", "") // TODO to be removed later
        recipe.set("defaultValues", defaultValues)
        recipe.set("preparation", "1")
        recipe.set("maxProd", 700)

        const productTypeOptions = await getProductTypeOptions(WITH_MANAGEMENT_MODE_FILTER)
        const type = productTypeOptions.find(item => item.value === values.type)
        const preparation = recipePreparations.find(item => item.value === "1")
        let specialInstruction = null
        if (type) {
            specialInstruction = `${type.adj} ${type.label.toLowerCase()} ${type.adj === "Ce" ? preparation.label.toLowerCase() : preparation.labelFem.toLowerCase()}`
        }

        recipe.set("specialInstruction", specialInstruction)
        await recipe.save()
        dispatch(showRecipeNewDetails(recipe.id))
    })
}

export function saveProductResume(id, values) {
    return async (dispatch) => {
        const recipe = await new Parse.Query(Recipe)
            .equalTo("objectId", id)
            .first()

        if (!recipe) {
            return
        }

        let ids = []
        Object.keys(values).filter(elem => elem.includes("internalTag")).forEach(key => {
            ids = ids.concat(values[key])
        })

        const keys = [
            "name",
            "ean",
            "spicy",
            "status",
            "type",
            "isActive",
            "brands",
            "legalName"
        ]

        keys.forEach(key => {
            recipe.set(key, values[key])
        })

        recipe.set("maxProd", parseInt(values.maxProd))

        const internalTags = (await new Parse.Query(ProductsTags)
            .containedIn("objectId", ids)
            .limit(parseLimitRequest)
            .find()) || []

        recipe.set("internalTag", internalTags)

        const chef = values.chef
            ? await getSingleChef(values.chef, false)
            : null
        recipe.set("chef", chef)
        await recipe.save()

        await dispatch(loadSingleRecipe(id))
    }
}

export function duplicateRecipe(currentRecipeId, newRecipeName, shouldRedirect = false) {
    return actionWithLoader(async (dispatch) => {
        const url = getServerUrl() + "/recipes/" + currentRecipeId + "/_duplicate"
        const result = await axiosCall(
            "post",
            url,
            { newRecipeName: newRecipeName },
            { "Content-Type": "application/json" }
        )
        if (result.status === 200 && result.data.success === true) {
            dispatch({
                type: "DUPLICATE_RECIPE",
                recipeSnackBar: { open: true, type: "success", message: newRecipeName + " a été  crée", duration: 5000 }
            })
            dispatch(loadRecipes())
        }
        else {
            dispatch({
                type: "DUPLICATE_RECIPE",
                recipeSnackBar: { open: true, type: "error", message: result.data.error, duration: 5000 }
            })
        }

        if (shouldRedirect) {
            dispatch(showRecipeList())
        }
    })
}

export function onEnterRecipes(store) {
    return onEnter({
        store,
        actionThunk: loadRecipes,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}

export function loadSingleRecipeData(id) {
    return actionWithLoader(async (dispatch) => {
        await Promise.all([
            dispatch(loadProductsTags()),
            dispatch(loadPackaging()),
            dispatch(loadGenericSections()),
            dispatch(loadChefs()),
            dispatch(loadProductTypeOptions(WITH_MANAGEMENT_MODE_FILTER)),
            (id) ? dispatch(loadSingleRecipe(id)) : () => { }
        ])
    })
}

export function loadSingleRecipeDataGeneral(id) {
    return actionWithLoader(async (dispatch) => {
        await Promise.all([
            dispatch(loadProductsTags()),
            dispatch(loadPackaging()),
            dispatch(loadChefs()),
            dispatch(loadProductTypeOptions(WITH_MANAGEMENT_MODE_FILTER)),
            dispatch(loadSingleRecipe(id))
        ])
    })
}

export function loadSingleRecipeDataForRecipeEdit(id) {
    return actionWithLoader(async (dispatch) => {
        await Promise.all([
            dispatch(loadGenericSections()),
            dispatch(loadSingleRecipe(id))
        ])
    })
}

export function loadSingleRecipeDataForProductionSteps(id) {
    return actionWithLoader(async (dispatch) => {
        await Promise.all([
            dispatch(loadGenericSections()),
             dispatch(loadSingleRecipe(id))
        ])
    })
}

export function loadRecipeDataForRecipeDetails(id) {
    return actionWithLoader(async (dispatch) => {
        await Promise.all([
            dispatch(loadPackaging()),
            dispatch(loadSingleRecipe(id))
        ])
    })
}

export function loadRecipeDataForComment(id) {
    return actionWithLoader(async (dispatch) => {
        await dispatch(loadSingleRecipe(id))
    })
}

export function onEnterSingleRecipe(store) {
    return onEnter({
        store,
        actionThunk: params => {
            return actionWithLoader(async dispatch => {
                const selectedTab = params?.tab || null
                if (!selectedTab) {
                    await dispatch(loadSingleRecipeData(params?.id))
                }
                if (selectedTab) {
                    switch (selectedTab) {
                        case "general":
                            await dispatch(loadSingleRecipeDataGeneral(params.id))
                            break
                        case "recipe":
                            await dispatch(loadSingleRecipeDataForRecipeEdit(params.id))
                            break
                        case "productionSteps":
                            await dispatch(loadSingleRecipeDataForProductionSteps(params.id))
                            break
                        case "details":
                            await dispatch(loadRecipeDataForRecipeDetails(params.id))
                            break
                        case "commentaires":
                            await dispatch(loadRecipeDataForComment(params.id))
                            break
                        default:
                            break
                    }
                }
            })
        }
    })
}

export function checkProductInCard(recipeId) {
    return actionWithLoader(async (dispatch) => {
        const productItems = await getProductInCard(recipeId, "Recipe")

        dispatch({
            type: "RECIPE_PRODUCT_ITEMS_CHECKED",
            productItems
        })
    })
}

export function updateRecipeFilters(change) {
    return actionWithLoader(async (dispatch) => {
        batch(() => {
            dispatch({
                type: "RECIPE_UPDATE_LIST_FILTERS",
                change
            })
            dispatch(loadRecipes())
        })
    })
}

export function saveRecipeImage(image, parseRecipe) {
    return actionWithLoader(
        async (dispatch) => {
            if (!image || !parseRecipe) {
                return
            }

            try {
                const parseImage = await saveImage(image)

                if (!parseImage) {
                    return
                }

                parseRecipe.addUnique("images", parseImage)
                await parseRecipe.save()

                dispatch({
                    type: "RECIPE_IMAGE_SAVED",
                    image: parseImage,
                    recipe: parseRecipe,
                    recipeSnackBar: {
                        open: true,
                        type: "success",
                        message: "Image enregistrée",
                        duration: 5000
                    }
                })

                dispatch(loadRecipes())
            } catch (e) {
                const error = { ...e, code: null }
                dispatch(showError(error))
            }
        }
    )
}

export function removeRecipeImage(image, parseRecipe) {
    return actionWithLoader(
        async (dispatch) => {
            if (!image || !parseRecipe) {
                return
            }

            try {
                const parseImage = await deleteImage(image)

                if (!parseImage) {
                    return
                }

                parseRecipe.remove("images", parseImage)
                await parseRecipe.save()

                dispatch({
                    type: "RECIPE_IMAGE_REMOVED",
                    image: parseImage,
                    recipe: parseRecipe,
                    recipeSnackBar: {
                        open: true,
                        type: "success",
                        message: "Image supprimée",
                        duration: 5000
                    }
                })

                dispatch(loadRecipes())
            } catch (e) {
                const error = { ...e, code: null }
                dispatch(showError(error))
            }
        }
    )
}


export function editNews(recipeId, news) {
    return async (dispatch) => {
        const recipe = await new Parse.Query(Recipe)
            .equalTo("objectId", recipeId)
            .first()

        if (!recipe) {
            dispatch({
                type: "RECIPE_SAVE_ERROR",
            })
            return
        }
        recipe.set("news", news)
        await recipe.save()

        dispatch(loadSingleRecipe(recipeId))
    }
}

export const downloadRecipesReport = (values) => {
    return async (dispatch) => {
        const data = []
        for (const value of values.products) {
            const recipe = value.product

            data.push({
                commercialName: recipe.commercialName,
                uniqueCode: recipe.uniqueCode,
                data: {
                    id: recipe.objectId,
                },
                type: "recipe",
                totalVolume: value.quantity,
            })
        }

        const csvFileName = `Rapport de commande - Extrait le ${dayjs().format("DD-MM-YY HH[h]mm")}`
        dispatch(downloadSupplierItemsByReportCsv({
            data,
            csvFileName,
            isRandD: true
        }))
    }
}

export const searchRecipesAutocomplete = (search, isSearchForPSEGeneration = false, productionDate = null) => {
    return async (dispatch) => {
        const recipes = await searchRecipes(search, isSearchForPSEGeneration, productionDate)

        dispatch({
            type: "SEARCH_RECIPES_LOADED",
            recipes,
        })
    }
}

export const clearSearchRecipesAutocomplete = () => {
    return async (dispatch) => {
        dispatch({
            type: "SEARCH_RECIPES_CLEARED",
        })
    }
}

export function exportAlkemics() {
    return async () => {
        const url = `${getServerUrl()}/recipes/exportAlkemics`
        downloadFile(url, "alkemics.csv")
    }
}

export function updateAllRecipeFoodcost() {
    return actionWithLoader(
        async () => {
            await updateAllRecipesFoodcost()
        }
    )
}

/** Routers */

export function showRecipeNewDetails(id) {
    return push(`/products/recipe/general/${id}`)
}

export function showRecipeList() {
    return push("/products/recipes")
}

export function showRecipe(recipeId) {
    if (recipeId) {
        return push(`/products/recipeOld/${recipeId}`)
    } else {
        return push("/products/recipe")
    }
}

export function showRecipeTab(tab, recipeId) {
    return push(`/products/recipe/${tab}/${recipeId ? recipeId : ""}`)
}

export function showAdvancedSearch() {
    return push("/products/advanced-search")
}
