import React, { useCallback, useLayoutEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { COLORS, roundNumber } from "../../../utils"
import { supplierItemTypes } from "../../../utils/supplierItemUtils"
import {
    generateStockUnitLabel,
    generateStockUnitWeightLabel,
} from "../../../utils/orderReception"
import {
    useDispatch,
    useSelector
} from "react-redux"
import {
    showOrderSupplierItemLots,
    showSingleOrder,
    updateOrderSupplierItemQuantity,
    validateLotReception
} from "../../../actions/OrderReception/OrderReception"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import {Box, Checkbox, Stack} from "@mui/material"
import OrderReceptionHeaderTitle from "./OrderReceptionHeaderTitle"
import OrderReceptionHeaderInfo from "./OrderReceptionHeaderInfo"
import {PEPSEResumeContainer} from "../../ProductionStepExecutions/preview/StyledExecutionPreviewComponents"
import {StyledItemLabel, StyledItemValue, StyledMainContainer} from "../../ProductionStepExecutions/styledComponents"
import ActionFooter from "../../../components/common/ActionFooter"

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    },
    container: {
        minHeight: "calc(100vh - 70px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 70
    },
    contentBloc: {
        display: "flex",
        justifyContent: "center"
    },
    title: {
        fontSize: 20,
        display: "flex",
        flexDirection: "column",
        color: "#262626",
        alignItems: "center",
        fontWeight: 500
    },
    subContentBloc: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    blocTitle: {
        fontSize: 20,
        fontWeight: 400,
        color: "#262626"
    },
    actualQuantityInput: {
        textAlign: "center"
    },
    expectedQuantity: {
        fontWeight: 400,
        fontSize: 40,
        color: "#262626",
        margin: "10px 0"
    },
    actualQuantity: {
        fontSize: 40,
        marginTop: "10px",
        width: 68
    },
    actualQuantityGood: {
        composes: "$actualQuantity",
        color: COLORS.PRIMARY_COLOR
    },
    actualQuantityGap: {
        composes: "$actualQuantity",
        color: COLORS.RED_NUTRITION
    },
    actualQuantityInitital: {
        composes: "$actualQuantity",
        color: COLORS.GREY_BORDER
    },
    label: {
        fontWeight: 400,
        color: "#7C7C7C",
        fontSize: 16,
        marginTop: 10
    },
    separator: {
        border: "1px solid #e8e8e8",
        height: 150,
        width: 1,
        marginLeft: 80,
        marginRight: 80
    },
    footerBloc: {
        composes: "flexRow center",
        justifyContent: "center"
    },
    checkboxLabel: {
        marginRight: 8,
        color: "#000"
    },
    infoTooltip: {
        backgroundColor: theme.palette.primary.main,
        color: "#ffff",
        fontSize: 14,
    },
    arrow: {
        color: theme.palette.primary.main,
    },
    infoIcon: {
        color: theme.palette.primary.main,
        cursor: "pointer",
        fontSize: 20
    },
    labelChip: {
        color: COLORS.WHITE,
        backgroundColor: "#48B468",
        paddingLeft: "4px",
        paddingRight: "4px",
        height: "26px",
        marginTop: "auto",
        marginBottom: "4px",
        fontSize: "16px",
        marginRight: "15px"
    },
    productName: {
        fontWeight: 700,
        color: COLORS.GREY_700,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px 16px",
        gap: "4px",
    },
    titleContainer: {
        height: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    rootQuantity: {
        border: "1px solid #E6E6E6",
        padding: "24px",
        gap: "24px",
        borderRadius: "8px"
    },
    labelContainer: {
        flexDirection: "row",
        gap: "8px"
    },
    mainLabel: {
        color: "#262626",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "22px"
    },
    labelFields: {
        color: "#555",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "22px"
    },
    headerCell: {
        color: "#555555",
        fontWeight: 400,
        paddingBottom: "8px !important",
        width: "150px"
    },
    value: {
        color: "#262626",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
    },
    quantityOutputError: {
        fontSize: 14,
        color: "#F44259"
    },
}))

const OrderReceptionQuantities = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const site = useSelector(state => state.orderReception.site)
    const date = useSelector(state => state.orderReception.date)
    const selectedOrderReception = useSelector(state => state.orderReception.selectedOrderReception)
    const selectedOrderSupplierItem = useSelector(state => state.orderReception.selectedOrderSupplierItem)
    const [receivedQuantity, setReceivedQuantity] = useState("")
    const creditNotes = new Map()
    const lots = []
    const [data, setData] = useState(null)
    const [cancelQuantitiesCreditNotes, setCancelQuantitiesCreditNotes] = useState(false)

    useLayoutEffect(() => {
        if (selectedOrderSupplierItem) {
            const orderWeight = (selectedOrderSupplierItem.supplierItem?.units?.order?.weight) || 1
            const stockWeight = (selectedOrderSupplierItem.supplierItem?.units?.stock?.weight) || 1
            const orderQuantity = (selectedOrderSupplierItem.quantities && selectedOrderSupplierItem.quantities.order) || 0
            setData({
                name: selectedOrderSupplierItem.supplierItem.name,
                type: supplierItemTypes[selectedOrderSupplierItem.supplierItem.type].label,
                reference: selectedOrderSupplierItem.supplierItem.reference,
                pricePerStockUnit: (selectedOrderSupplierItem.supplierItem.units && selectedOrderSupplierItem.supplierItem.units.stock && selectedOrderSupplierItem.supplierItem.units.stock.price) || "",
                quantity: roundNumber((orderQuantity * orderWeight) / stockWeight),
                unitType: selectedOrderSupplierItem && generateStockUnitLabel(selectedOrderSupplierItem.supplierItem),
                stockUnit: selectedOrderSupplierItem && generateStockUnitWeightLabel(selectedOrderSupplierItem.supplierItem)
            })
        }
    }, [selectedOrderSupplierItem])

    const onBack = useCallback(() => {
        dispatch(showSingleOrder(site.objectId, date, selectedOrderReception.objectId))
    }, [site, date, selectedOrderReception])

    const handleChange = (e) => {
        const value = e.target.value !== "" ? parseFloat(e.target.value) : null

        setReceivedQuantity(value)
    }

    const handleConfirm = async () => {
        if (receivedQuantity !== null && receivedQuantity !== "") {
            const values = {
                quantity: receivedQuantity,
                cancelQuantitiesCreditNotes
            }

            await dispatch(updateOrderSupplierItemQuantity(selectedOrderReception, selectedOrderSupplierItem.supplierItem.objectId, values))

            if (values.quantity === 0) {
                dispatch(validateLotReception(selectedOrderReception, selectedOrderSupplierItem, lots, creditNotes))
            } else {
                await dispatch(showOrderSupplierItemLots(site.objectId, date, selectedOrderReception.objectId, selectedOrderSupplierItem.supplierItem.objectId))
            }
        }
    }

    const getInputClassName = () => {
        if (receivedQuantity === null || receivedQuantity === "") return classes.actualQuantityInitital
        if (receivedQuantity === data.quantity) {
            return classes.actualQuantityGood
        }
        else {
            return classes.actualQuantityGap
        }
    }

    const handleCancelQuantitiesCreditNotes = (event) => {
        setCancelQuantitiesCreditNotes(event.target.checked)
    }

    return (
      <div className={classes.wrapper}>
          <div>
              <GenericTopBarHeader
                leftAction={<GenericHeaderReturnButton handleClick={onBack} />}
                title={<OrderReceptionHeaderTitle sx={{ fontSize: 16 }}/>}
                info={selectedOrderReception && <OrderReceptionHeaderInfo info={`/ ... / ${selectedOrderSupplierItem && selectedOrderSupplierItem.supplierItem.name}`}/>}
              />
          </div>
          <StyledMainContainer sx={{pt: 0}}> {/** for the fixed header */}
              <Stack mb={4}>
                  <Stack sx={{ p: 3 }} gap={3}>
                      <PEPSEResumeContainer sx={{ position: "relative" }}>
                          <Stack spacing={4}>
                              {/* head */}
                              <Stack spacing={2.2} direction="row" alignItems="center">
                                  <Typography variant="h1" className={classes.title}>
                                      {selectedOrderReception && selectedOrderSupplierItem?.supplierItem?.name}
                                  </Typography>
                              </Stack>
                              {/* resume */}
                              <Stack spacing={4} direction="row">
                                  {/* transformation */}
                                  <Stack spacing={1}>
                                      <StyledItemLabel>
                                          Référence
                                      </StyledItemLabel>
                                      <StyledItemValue>
                                          {selectedOrderSupplierItem?.supplierItem?.reference}
                                      </StyledItemValue>
                                  </Stack>
                                  {/* recipe & section */}
                                  <Stack spacing={1}>
                                      <StyledItemLabel>
                                          Unité de stock
                                      </StyledItemLabel>
                                      <StyledItemValue>
                                          {selectedOrderSupplierItem && generateStockUnitLabel(selectedOrderSupplierItem?.supplierItem)}
                                      </StyledItemValue>
                                  </Stack>
                              </Stack>
                          </Stack>
                      </PEPSEResumeContainer>
                      <Stack spacing={4}>
                          <div>
                              <div className={classes.root}>
                                  <Box className={classes.titleContainer}>
                                      <Typography variant="h6" id="tableTitle">Quantité réceptionnée </Typography>
                                  </Box>
                                  <Box>
                                      <Typography variant="h8" id="tableTitle" sx={{color: "#7C7C7C", fontSize: 16, }}>Saisissez la quantité réceptionnée.</Typography>
                                  </Box>
                              </div>
                          </div>
                      </Stack>
                      <Stack className={classes.rootQuantity}>
                          <Stack className={classes.labelContainer}>
                              <img src="/img/cube-focus.svg" alt="Quantité" />
                              <span className={classes.mainLabel}>Quantité</span>
                          </Stack>
                          <Stack className={classes.list}>
                              <div className={classes.contentBloc}>
                                  <div className={classes.subContentBloc}>
                                      <Typography className={classes.blocTitle}>
                                          Quantité commandée
                                      </Typography>
                                      <span className={classes.expectedQuantity}>
                                        {data && data.quantity}
                                      </span>
                                      <Typography className={classes.label}>
                                          {data && data.unitType}
                                      </Typography>
                                      <Typography className={classes.label}>
                                          = {data && selectedOrderSupplierItem && roundNumber(data.quantity * selectedOrderSupplierItem?.supplierItem?.units.stock.weight, 2)} kg
                                      </Typography>
                                  </div>
                                  <div className={classes.separator} />
                                  <div className={classes.subContentBloc}>
                                      <Typography className={classes.blocTitle}>
                                          Quantité réceptionnée
                                      </Typography>
                                      <TextField
                                        variant="standard"
                                        name="amount"
                                        type="number"
                                        value={receivedQuantity}
                                        onChange={handleChange}
                                        autoFocus
                                        className={classes.actualQuantityInput}
                                        placeholder="0"
                                        inputProps={{
                                            className: getInputClassName(),
                                            style: { textAlign: "center" },
                                            min: 0
                                        }}
                                      />
                                      <Typography className={classes.label}>
                                          {data && data.unitType}
                                      </Typography>
                                      <Typography className={classes.label}>
                                          = {selectedOrderSupplierItem && roundNumber(receivedQuantity * selectedOrderSupplierItem.supplierItem.units.stock.weight, 2)} kg
                                      </Typography>
                                      {receivedQuantity === null &&
                                        (<span className={classes.quantityOutputError}>
                                          Veuillez indiquer la quantité réceptionnée.
                                        </span>)
                                      }
                                  </div>
                              </div>
                          </Stack>
                          <Stack>
                              <div className={classes.footerBloc}>
                                  <div>
                                      <Checkbox
                                        checked={cancelQuantitiesCreditNotes}
                                        onChange={handleCancelQuantitiesCreditNotes}
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                        color="secondary"
                                      />
                                  </div>
                                  <Typography className={classes.checkboxLabel}>
                                      Le fournisseur a livré un article de substitution
                                  </Typography>
                              </div>
                          </Stack>
                      </Stack>
                  </Stack>
              </Stack>
              <ActionFooter
                rightAction={(
                  <Button variant="contained" onClick={handleConfirm}>
                      VALIDER
                  </Button>
                )}
              />

          </StyledMainContainer>
      </div>
    )
}

export default OrderReceptionQuantities